import Vue from 'vue'
import * as Sentry from '@sentry/vue'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://7540b43ca18547c182a35598f770da3a@sentry.io/1882627',
    ignoreErrors: ['NavigationDuplicated'],
  })
}
