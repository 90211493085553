const salaryTypes = {
  hour: {
    id: 'hour',
    name: 'Horaire',
    unit: 'h',
    tag: 'info',
    calc: ({ workedHours, profile }) => {
      return workedHours * profile.rates.hour.rate
    },
  },

  month: {
    id: 'month',
    name: 'Mensuel',
    unit: 'm',
    tag: 'success',
    calc: ({ profile }) => {
      return profile.rates.month.rate
    },
  },
}

export default salaryTypes
