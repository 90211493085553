export const accessLevels = {
  none: 0,
  headwaiter: 1,
  supervisor: 2,
  manager: 3,
  boss: 4,
  admin: 5,
}

// The minimum access level for each user actions
export const userActions = {
  editOwnShiftDetails: 1,
  readShiftDetails: 2,

  createFuturShifts: 3,
  editOthersShiftDetails: 3,
  editInvoiceIssued: 3,
  editInvoicePaid: 3,
  editExpenseInvoices: 3,
  deleteOtherUsersMessages: 3,
  accessShiftControls: 3,
  openOptions: 3,
  openPayroll: 3,
  openTeam: 3,
  openRemoval: 3,
  readWriteNotes: 3,
  accessV2: 3,

  openAccounting: 4,
  openResults: 4,

  incorporate: 5,
  openAdmin: 5,
  mock: 5,
}
