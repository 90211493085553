import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

export default function getNightHours(start, end, nightStart) {
  if (!nightStart) return 0
  const startMoment = moment.unix(start)
  const endMoment = moment.unix(end)

  const nightStartMoment = startMoment
    .clone()
    .hours(nightStart.replace(':00', ''))

  if (nightStart === '00:00') nightStartMoment.add(1, 'day') // As midnight is the begin of a day we need to add 24h when setting midnight
  const nightEndMoment = nightStartMoment.clone().add(12, 'hours')

  const workRange = moment.range(startMoment, endMoment)
  const nightRange = moment.range(nightStartMoment, nightEndMoment)

  let nightHours = 0
  const intersection = nightRange.intersect(workRange)

  if (intersection !== null) {
    nightHours = intersection.valueOf() / 3600000
  }
  return nightHours
}
