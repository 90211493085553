import router from '@/router/index'

const state = {
  editMode: false,
}

const getters = {
  isEditing: (state) => !!state.editMode,
  getEditMode: (state) => state.editMode,
}

const mutations = {
  UPDATE_EDIT_MODE: (state, string) => {
    state.editMode = string !== '' ? string : false
  },
}

const actions = {
  updateEditMode: (store, state) => {
    store.commit('UPDATE_EDIT_MODE', state)
  },
  quitEditMode: (store) => {
    store.commit('UPDATE_EDIT_MODE', false)
  },
  closeShiftEditor: (store) => {
    router.push({
      name: 'week',
      params: { week: router.currentRoute.params.week },
    })
    store.dispatch('quitEditMode')
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
