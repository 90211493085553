<template>
  <div id="app">
    <transition name="page-fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import debounce from 'just-debounce'

export default {
  created() {
    this.handleWindowResize()
    window.addEventListener('resize', debounce(this.handleWindowResize, 100))
  },

  methods: {
    ...mapActions(['updateWindowWidth']),

    handleWindowResize() {
      this.updateWindowWidth(window.innerWidth)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/main/main.scss';

#app {
  box-sizing: border-box;
  height: 100vh;
}
.page-fade-enter-active {
  transition: opacity 0.4s $ease-out-quad;
}
.page-fade-leave-active {
  transition: opacity 0.4s $ease-in-quad;
}
.page-fade-enter {
  opacity: 0;
}
.page-fade-leave-to {
  opacity: 0;
}
</style>
