import { firestoreAction } from 'vuexfire'
import { weekRef } from '@/db/references'

import shiftTotals from '@/computation/shiftTotals'
import dayTotals from '@/computation/dayTotals'
import accountingTotals from '@/computation/accountingTotals'
import expenseList from '@/computation/expenseList'
import cashBox from '@/computation/cashBox'
import getDuration from '@/utils/getDuration'
const state = {
  week: {},
}

const getters = {
  getWeekShift: (state, getters) => (day, shift) => {
    if (!state.week || !('days' in state.week)) return false
    if (day in state.week.days && shift in state.week.days[day].shifts) {
      const tallyType = getters.getOption('tally')
      return shiftTotals(state.week.days[day].shifts[shift], tallyType)
    } else {
      return false
    }
  },

  getWeekDay: (state, getters) => (day) => {
    const dontCountCoins = !getters.getOption('countCoins')

    if (!state.week || !('days' in state.week)) return false
    if (day in state.week.days) {
      const tallyType = getters.getOption('tally')
      const { tally = 0 } = state.week.days[day]
      const shifts = Object.entries(state.week.days[day].shifts).map(
        ([, shift]) => shiftTotals(shift, tallyType, dontCountCoins)
      )
      const totals = dayTotals(shifts, tally, tallyType)

      return {
        shifts,
        totals,
        tally,
      }
    } else {
      return false
    }
  },

  getWeekAccountingTotals: (state, getters) => {
    const dontCountCoins = !getters.getOption('countCoins')
    const tallyType = getters.getOption('tally')
    const shiftTypes = getters.getShiftTypes

    return accountingTotals(state.week, dontCountCoins, tallyType, shiftTypes)
  },

  getWeekWorkedHours: (state) => {
    const totals = {
      all: 0,
    }
    for (const day of Object.values(state.week.days)) {
      for (const shift of Object.values(day.shifts)) {
        for (const [roleId, role] of Object.entries(shift.timesheet)) {
          for (const person of Object.values(role)) {
            const duration = getDuration(person.start, person.end)
            totals.all += duration
            if (totals[roleId]) {
              totals[roleId] += duration
            } else {
              totals[roleId] = duration
            }
          }
        }
      }
    }
    return totals
  },

  getWeekExpenses: (state) => {
    return expenseList(state.week)
  },

  getCashBox: (state, getters) => {
    const dontCountCoins = !getters.getOption('countCoins')
    const tallyType = getters.getOption('tally')

    return cashBox(state.week, dontCountCoins, tallyType)
  },
}

const mutations = {
  RESET_WEEK: (state) => {
    state.week = {}
  },
}

const actions = {
  setWeekRef: firestoreAction(({ bindFirestoreRef }, ref) => {
    bindFirestoreRef('week', ref)
  }),
  resetWeekRef: async ({ commit, dispatch }, week) => {
    await commit('RESET_WEEK')
    dispatch('setWeekRef', weekRef(week))
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
