const state = {
  currentBar:
    process.env.NODE_ENV === 'production'
      ? window.location.hostname.split('.').slice(-2).join('.')
      : process.env.VUE_APP_BAR,
  vaultIsOpened: false,
  unpaidIsOpened: false,
  removalIsOpened: false,
}

const getters = {
  currentBar: (state) => state.currentBar,
  currentDomain: (state) => state.currentBar.replace(/-be$/, '.be'),
  vaultIsOpened: (state) => state.vaultIsOpened,
  unpaidIsOpened: (state) => state.unpaidIsOpened,
  removalIsOpened: (state) => state.removalIsOpened,
}

const mutations = {
  CLOSE_VAULT: (state) => {
    state.vaultIsOpened = false
  },
  OPEN_VAULT: (state) => {
    state.vaultIsOpened = true
  },
  CLOSE_UNPAID: (state) => {
    state.unpaidIsOpened = false
  },
  OPEN_UNPAID: (state) => {
    state.unpaidIsOpened = true
  },
  CLOSE_REMOVAL: (state) => {
    state.removalIsOpened = false
  },
  OPEN_REMOVAL: (state) => {
    state.removalIsOpened = true
  },
}

const actions = {
  closeVault: (store, state) => {
    store.commit('CLOSE_VAULT', state)
  },
  openVault: (store, state) => {
    store.commit('OPEN_VAULT', state)
  },
  closeUnpaid: (store, state) => {
    store.commit('CLOSE_UNPAID', state)
  },
  openUnpaid: (store, state) => {
    store.commit('OPEN_UNPAID', state)
  },
  closeRemoval: (store, state) => {
    store.commit('CLOSE_REMOVAL', state)
  },
  openRemoval: (store, state) => {
    store.commit('OPEN_REMOVAL', state)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
