import rateTypes from '@/computation/rateTypes'

export default function normalCalculation(
  profile,
  { debt, loans, workedHours }
) {
  // NORMAL CALCULATION STRATEGY:
  // CASH = SALAIRE TOTAL - BANQUE - AVANCES

  // SALAIRE EN BANQUE
  const bankAmount = profile.bankAmount ?? 0

  // SALAIRE TOTAL = heures x €/h + jours x €/j + fixe
  let pay = 0
  if (profile.rates.hour.active) {
    pay += rateTypes.hour.calc({ workedHours, profile })
  }
  if (profile.rates.month.active) {
    pay += rateTypes.month.calc({ profile })
  }

  // CASH = SALAIRE TOTAL - BANQUE - AVANCES - DETTES
  const cash = Math.max(0, pay - bankAmount - loans - debt)

  // SOLDE NEGATIF
  const negative = Math.min(pay - bankAmount - loans - debt, 0)

  return {
    contractHours: null,
    paidHours: null,
    outOfContractPay: null,
    pay,
    cash,
    negative,
    bankAmount,
  }
}
