const expenseList = (period) => {
  if (!period || typeof period !== 'object' || !('days' in period)) return []

  const expenseList = []

  Object.entries(period.days).forEach(([dayId, day]) => {
    Object.entries(day.shifts).forEach(([shiftId, shift]) => {
      shift.balance.expenses.forEach((expense) => {
        expenseList.push({
          ...expense,
          dayId,
          shiftId,
        })
      })
    })
  })

  return expenseList
}

export default expenseList
