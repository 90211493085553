/* eslint-disable no-console */
import store from '@/store/index'

const DEBUG = false

export default function guard(to, from, next) {
  debugGuard('Getting into route', to.fullPath)

  if (!store.getters.isLoaded && to.name !== 'loading') {
    debugGuard('But firebase is not loaded so redirecting to loading component')
    next({
      name: 'loading',
      query: { redirect: to.fullPath },
    })
  } else {
    if (to.matched.some((record) => record.meta.authRequired)) {
      debugGuard('which is protected')
      if (!store.getters.isAuthenticated) {
        debugGuard('but you ARE NOT logged in, so sending you to login')
        next({
          name: 'login',
          query: { redirect: to.fullPath },
        })
      } else {
        debugGuard('and you ARE logged in, so continue')
        debugGuard('but wait, do you need a special clearence in this page?')
        if (to.matched.some((record) => record.meta.authorization)) {
          debugGuard(
            'sorry, you do! Are you authorized to get this particular page?'
          )
          if (
            to.matched
              .filter((record) => record.meta.authorization)
              .map((record) =>
                store.getters.currentUserCan(record.meta.authorization)
              )
              .every((record) => record === true)
          ) {
            debugGuard('Mr President, please proceed')
            next()
          } else {
            debugGuard('Sorry cant let you get in here!')
            if (from.name === 'loading') {
              debugGuard('Redirecting to dashboard')
              next({
                name: 'dashboard',
                replace: true,
              })
            } else {
              debugGuard('Aborting')
              next(false)
            }
          }
        } else {
          debugGuard('nope, go on!')
          next()
        }
      }
    } else {
      debugGuard('which is not protected so proceed')
      next()
    }
  }
}

function debugGuard(...content) {
  if (DEBUG) console.log(...content)
}
