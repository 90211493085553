import moment from 'moment'
import { accessLevels, userActions } from '@/db/admin/authorization'

const state = {
  user: null,
  token: null,
  loaded: false,
}

const getters = {
  getUser: (state) => state.user,
  getCurrentUser: (state, getters) => {
    const emptyUser = {
      name: '',
      access: false,
    }
    if (!state.user) return emptyUser
    if (state.user.email === 'aldealdo@gmail.com') {
      return {
        name: 'Admin',
        access: 'admin',
      }
    }
    return getters.getPersonById(state.user.uid) || emptyUser
  },
  getUserLastConnection: (state) => {
    if (!state.user) return
    return moment(state.user.metadata.lastSignInTime)
      .locale('fr')
      .format('dddd DD MMMM YYYY à HH:mm')
  },
  currentUserAccessLevel: (state, getters) => {
    if (!getters.getCurrentUser.access) return 0
    return accessLevels[getters.getCurrentUser.access]
  },
  currentUserCan: (state, getters) => (action) => {
    if (action in userActions) {
      return getters.currentUserAccessLevel >= userActions[action]
    }
    return false
  },
  currentUserHigherThan: (state, getters) => (personId) => {
    return getters.currentUserAccessLevel >= getters.personAccessLevel(personId)
  },
  getToken: (state) => state.token,
  isAuthenticated: (state) => state.user !== null,
  isLoaded: (state) => state.loaded === true,
}

const mutations = {
  UPDATE_USER(state, user) {
    state.user = user
  },
  UPDATE_TOKEN(state, token) {
    state.token = token
  },
  IS_LOADED(state) {
    if (!state.loaded) state.loaded = true
  },
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions,
}
