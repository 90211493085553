import optionsRoutes from '@/router/optionsRoutes'

export default [
  {
    path: '/',
    redirect: {
      name: 'dashboard',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: 'LoginView' */ '@/views/LoginView'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () =>
      import(/* webpackChunkName: 'LogoutView' */ '@/views/LogoutView'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/loading',
    name: 'loading',
    component: () =>
      import(/* webpackChunkName: 'LoadingView' */ '@/views/LoadingView'),
  },
  {
    path: '/app',
    component: () =>
      import(/* webpackChunkName: 'AppView' */ '@/views/AppView'),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () =>
          import(
            /* webpackChunkName: 'DashboardView' */ '@/views/DashboardView'
          ),
      },
      {
        path: 'admin',
        name: 'admin',
        component: () =>
          import(/* webpackChunkName: 'AdminView' */ '@/views/AdminView'),
      },
      {
        path: 'shifts',
        name: 'shifts',
        component: () =>
          import(/* webpackChunkName: 'ShiftsView' */ '@/views/ShiftsView'),
        children: [
          {
            path: 'week/:week?',
            name: 'week',
            component: () =>
              import(/* webpackChunkName: 'WeekView' */ '@/views/WeekView'),
            children: [
              {
                name: 'shift-editor',
                path: 'shift-day/:shiftDay/shift-type/:shiftType',
                component: () =>
                  import(
                    /* webpackChunkName: 'ShiftFlipper' */ '@/components/Shift/ShiftFlipper'
                  ),
                props: true,
                meta: {
                  bodyClass: 'locked',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'options',
        name: 'options',
        component: () =>
          import(/* webpackChunkName: 'OptionsView' */ '@/views/OptionsView'),
        meta: {
          authorization: 'openOptions',
        },
        children: optionsRoutes,
      },
      {
        path: 'team',
        name: 'team',
        component: () =>
          import(/* webpackChunkName: 'TeamView' */ '@/views/TeamView'),
        meta: {
          authorization: 'openTeam',
        },
        children: [
          {
            name: 'person-modal',
            path: ':person',
            component: () =>
              import(
                /* webpackChunkName: 'TeamView' */ '@/components/Team/PersonModal'
              ),
          },
        ],
      },
      {
        path: 'payroll/:month?',
        name: 'payroll',
        component: () =>
          import(/* webpackChunkName: 'PayrollView' */ '@/views/PayrollView'),
        props: true,
        meta: {
          authorization: 'openPayroll',
        },
        children: [
          {
            name: 'payroll-flipper',
            path: 'details/:uid/:edit?',
            component: () =>
              import(
                /* webpackChunkName: 'PayrollFlipper' */ '@/components/Payroll/PayrollFlipper'
              ),
            props: true,
            meta: {
              bodyClass: 'locked',
            },
          },
          {
            name: 'payroll-total',
            path: 'total',
            component: () =>
              import(
                /* webpackChunkName: 'PayrollTotal' */ '@/components/Payroll/Total'
              ),
            props: true,
            meta: {
              bodyClass: 'locked',
            },
          },
          {
            name: 'payroll-table',
            path: 'table',
            component: () =>
              import(
                /* webpackChunkName: 'PayrollTable' */ '@/components/Payroll/Table'
              ),
            props: true,
            meta: {
              bodyClass: 'locked',
            },
          },
          {
            name: 'payroll-team',
            path: 'team',
            component: () =>
              import(
                /* webpackChunkName: 'PayrollTeam' */ '@/components/Payroll/Team'
              ),
            props: true,
            meta: {
              bodyClass: 'locked',
            },
          },
        ],
      },
      {
        path: 'accounting/:week?',
        name: 'accounting',
        component: () =>
          import(
            /* webpackChunkName: 'AccountingView' */ '@/views/AccountingView'
          ),
        props: true,
        meta: {
          authorization: 'openAccounting',
        },
        children: [
          {
            name: 'accounting-modal',
            path: 'day/:day',
            component: () =>
              import(
                /* webpackChunkName: 'AccountingModal' */ '@/components/Accounting/Modal'
              ),
            props: true,
            meta: {
              bodyClass: 'locked',
            },
          },
          {
            name: 'accounting-total',
            path: 'total',
            component: () =>
              import(
                /* webpackChunkName: 'AccountingTotal' */ '@/components/Accounting/Total'
              ),
            props: true,
            meta: {
              bodyClass: 'locked',
            },
          },
          {
            name: 'accounting-expenses',
            path: 'expenses',
            component: () =>
              import(
                /* webpackChunkName: 'AccountingExpenses' */ '@/components/Accounting/Expenses'
              ),
            props: true,
            meta: {
              bodyClass: 'locked',
            },
          },
          {
            name: 'accounting-cashbox',
            path: 'cashbox',
            component: () =>
              import(
                /* webpackChunkName: 'AccountingCashbox' */ '@/components/Accounting/Cashbox'
              ),
            props: true,
            meta: {
              bodyClass: 'locked',
            },
          },
        ],
      },
      {
        path: 'results/:month?',
        name: 'results',
        component: () =>
          import(/* webpackChunkName: 'ResultsView' */ '@/views/ResultsView'),
        props: true,
        meta: {
          authorization: 'openResults',
        },
        children: [
          {
            name: 'results-expenses',
            path: 'expenses',
            component: () =>
              import(
                /* webpackChunkName: 'ResultsExpenses' */ '@/components/Results/Expenses'
              ),
            props: true,
          },
          {
            name: 'results-estimator',
            path: 'estimator',
            component: () =>
              import(
                /* webpackChunkName: 'ResultsEstimator' */ '@/components/Results/Estimator'
              ),
            props: true,
          },
        ],
      },
      {
        name: 'results-multiple',
        path: 'results-multiple',
        component: () =>
          import(
            /* webpackChunkName: 'ResultMultiple' */ '@/views/ResultsMultipleView'
          ),
        props: true,
      },
    ],
  },
  {
    path: '*',
    name: '404',
    component: () =>
      import(/* webpackChunkName: 'NotFoundView' */ '@/views/NotFoundView'),
    meta: {
      authRequired: true,
    },
  },
]
