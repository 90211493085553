import { firestoreAction } from 'vuexfire'

const state = {
  unpaidList: [],
}

const getters = {
  getUnpaidList: (state) => state.unpaidList,
}

const mutations = {}

const actions = {
  setUnpaidRef: firestoreAction(({ bindFirestoreRef }, ref) => {
    bindFirestoreRef('unpaidList', ref)
  }),
}

export default {
  state,
  getters,
  mutations,
  actions,
}
