import Vue from 'vue'
import VueBodyClass from 'vue-body-class'
import Router from 'vue-router'
import routes from './routes'
import guard from './guard'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
})

const vueBodyClass = new VueBodyClass(routes)
router.beforeEach(guard)
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next)
})

export default router
