<template>
  <div class="box">
    <h6 v-if="title">{{ title }}</h6>
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: 'Box',

  props: {
    title: {
      type: String,
      required: false,
    },
  },
}
</script>

<style lang="scss">
.box {
  position: relative;
  border-radius: layout(border-radius);
  padding: layout(spacing) * 0.5;
  color: colors(tertiary);
  background-color: tint(colors(primary), 95%);
  border: 1px solid tint(colors(tertiary), 85%);
  overflow: hidden;
  @include add-box-shadow-center(rgba(black, 0.05));

  &:not(:last-of-type) {
    margin-bottom: layout(spacing) * 0.5;
  }

  > h6 {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.2rem;
  }
  p {
    font-size: 1.2rem;
  }

  > dl {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
