import toEuros from './toEuros'
import toHumanDate from './toHumanDate'
import toSlug from './toSlug'
import toPercent from './toPercent'
import truncate from './truncate'
import readableMonth from './readableMonth'
import readableDay from './readableDay'
import readableHour from './readableHour'
import ucFirst from './ucFirst'
import toDayAndHour from './toDayAndHour'

export default {
  install(Vue) {
    Vue.filter('toDayAndHour', toDayAndHour)
    Vue.filter('toEuros', toEuros)
    Vue.filter('toPercent', toPercent)
    Vue.filter('toHumanDate', toHumanDate)
    Vue.filter('toSlug', toSlug)
    Vue.filter('truncate', truncate)
    Vue.filter('readableMonth', readableMonth)
    Vue.filter('readableDay', readableDay)
    Vue.filter('readableHour', readableHour)
    Vue.filter('ucFirst', ucFirst)
  },
}
