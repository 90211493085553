import { firestoreAction } from 'vuexfire'
import vaultTotal from '@/computation/vault'

const state = {
  vaultList: [],
}

const getters = {
  getVaultList: (state) =>
    state.vaultList.map((vault) => {
      return {
        ...vault,
        total: vaultTotal(vault),
      }
    }),
}

const mutations = {}

const actions = {
  setVaultRef: firestoreAction(({ bindFirestoreRef }, ref) => {
    bindFirestoreRef('vaultList', ref)
  }),
}

export default {
  state,
  getters,
  mutations,
  actions,
}
