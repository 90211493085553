import { firestoreAction } from 'vuexfire'
import { accessLevels } from '@/db/admin/authorization'

const state = {
  team: [],
}

const getters = {
  getTeam: (state) =>
    state.team.map((person) => {
      return {
        ...person,
        id: person.id,
      }
    }),
  getTeamAsObject: (state) => {
    const team = {}
    state.team.forEach((person) => {
      team[person.id] = {
        ...person,
        id: person.id,
      }
    })
    return team
  },
  getPersonById: (state, getters) => (personId) => {
    return getters.getTeamAsObject[personId]
  },
  personAccessLevel: (state, getters) => (personId) => {
    const person = getters.getPersonById(personId)
    if (!person || !person.access) return 0
    return accessLevels[person.access]
  },
}

const mutations = {}

const actions = {
  setTeamRef: firestoreAction(({ bindFirestoreRef }, ref) => {
    bindFirestoreRef('team', ref)
  }),
}

export default {
  state,
  getters,
  mutations,
  actions,
}
