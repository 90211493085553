<template>
  <div class="column" :class="customClasses">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    noPadding: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    customClasses() {
      return [{ 'column--no-padding': this.noPadding }]
    },
  },
}
</script>

<style lang="scss">
.column {
  @include media('small') {
    display: block;
    padding: 0 (layout(spacing) * 0.5);
    padding-bottom: (layout(spacing) * 0.5);
  }

  flex: 1 0;
  display: flex;
  flex-direction: column;
  padding: 0 (layout(spacing) * 0.5) 0 0;

  &:first-of-type {
    padding-left: (layout(spacing) * 0.5);
  }

  &--no-padding {
    @include media('small') {
      padding: 0;
      padding-bottom: (layout(spacing) * 0.5);
    }
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }

  > * {
    flex: 1 0 auto;
  }
}
</style>
