const vaultTotals = (vault) => {
  const rollsTotals = vault.rolls.reduce(
    (results, roll) => results + roll.amount * roll.multiplier,
    0
  )
  const billsTotals = vault.bills.reduce(
    (results, bill) => results + bill.amount * bill.multiplier,
    0
  )

  return rollsTotals + billsTotals + vault.loose + vault.expenses
}

export default vaultTotals
