export default [
  {
    name: 'general-options',
    path: 'general',
    component: () => import('@/components/Options/Panels/GeneralOptions'),
    meta: {
      label: 'Général',
    },
  },
  {
    name: 'shift-options',
    path: 'shift',
    component: () => import('@/components/Options/Panels/ShiftOptions'),
    meta: {
      label: 'Services',
    },
  },
  {
    name: 'payroll-options',
    path: 'payroll',
    component: () => import('@/components/Options/Panels/PayrollOptions'),
    meta: {
      label: 'Salaires',
    },
  },
  {
    name: 'accounting-options',
    path: 'accounting',
    component: () => import('@/components/Options/Panels/AccountingOptions'),
    meta: {
      label: 'Comptabilité',
    },
  },
  {
    name: 'vault-options',
    path: 'vault',
    component: () => import('@/components/Options/Panels/VaultOptions'),
    meta: {
      label: 'Coffre',
    },
  },
  {
    name: 'unpaid-options',
    path: 'unpaid',
    component: () => import('@/components/Options/Panels/UnpaidOptions'),
    meta: {
      label: 'Impayés',
    },
  },
  {
    name: 'shift-type-options',
    path: 'shift-type',
    component: () => import('@/components/Options/Panels/ShiftTypeOptions'),
    meta: {
      label: 'Types de Services',
      advanced: true,
    },
  },
  {
    name: 'units-options',
    path: 'units',
    component: () => import('@/components/Options/Panels/UnitsOptions'),
    meta: {
      label: 'Postes de travail',
      advanced: true,
    },
  },
  {
    name: 'non-cash-types-options',
    path: 'non-cash-types',
    component: () => import('@/components/Options/Panels/NonCashTypesOptions'),
    meta: {
      label: 'Types de Cartes',
      advanced: true,
    },
  },
  {
    name: 'comments-options',
    path: 'comments',
    component: () => import('@/components/Options/Panels/CommentsOptions'),
    meta: {
      label: 'Types de Commentaires',
      advanced: true,
    },
  },
  {
    name: 'expenses-options',
    path: 'expenses',
    component: () => import('@/components/Options/Panels/ExpensesOptions'),
    meta: {
      label: 'Types de Sorties',
      advanced: true,
    },
  },
  {
    name: 'counters-options',
    path: 'counters',
    component: () => import('@/components/Options/Panels/CountersOptions'),
    meta: {
      label: 'Compteurs',
      advanced: true,
    },
  },
]
