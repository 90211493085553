const getPayrollTotals = (payrolls, workforce) => {
  // console.dir({
  //   payrolls: payrolls.map((person) => person.totals.bankAmount),
  //   workforce,
  // })
  const totals = {
    workedHours: 0,
    dayHours: 0,
    nightHours: 0,
    days: 0,
    loans: 0,
    contractHours: 0,
    paidHours: 0,
    outOfContractPay: 0,
    pay: 0,
    cash: 0,
    negative: 0,
    bankAmount: 0,
    debt: 0,
    workforce,
    daysPerRole: {},
    workedHoursPerRole: {},
  }

  // TODO LOOP THRU OBJECT ENTRIES
  payrolls.forEach((personPayroll) => {
    if (personPayroll === undefined) return

    totals.workedHours += personPayroll.totals.workedHours
    totals.dayHours += personPayroll.totals.dayHours
    totals.nightHours += personPayroll.totals.nightHours
    totals.days += personPayroll.totals.days
    totals.loans += personPayroll.totals.loans
    totals.contractHours += personPayroll.totals.contractHours
    totals.paidHours += personPayroll.totals.paidHours
    totals.outOfContractPay += personPayroll.totals.outOfContractPay
    totals.pay += personPayroll.totals.pay
    totals.cash += personPayroll.totals.cash
    totals.negative += personPayroll.totals.negative
    totals.bankAmount += personPayroll.totals.bankAmount
    totals.debt += personPayroll.totals.debt
    for (const role in personPayroll.totals.daysPerRole) {
      if (role in totals.daysPerRole) {
        totals.daysPerRole[role] += personPayroll.totals.daysPerRole[role]
      } else {
        totals.daysPerRole[role] = personPayroll.totals.daysPerRole[role]
      }
      if (role in totals.workedHoursPerRole) {
        totals.workedHoursPerRole[role] +=
          personPayroll.totals.workedHoursPerRole[role]
      } else {
        totals.workedHoursPerRole[role] =
          personPayroll.totals.workedHoursPerRole[role]
      }
    }
  })
  // console.dir({ totals })
  return totals
}

export default getPayrollTotals
