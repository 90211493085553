import { bar, auth } from '@/db'

export default {
  install: (Vue) => {
    Vue.prototype.$bar = bar
    Vue.prototype.$auth = {
      login: async (email, pass) => {
        await auth.signInWithEmailAndPassword(email, pass)
      },
      logout: async () => {
        await auth.signOut()
      },
    }
  },
}
