import { firestoreAction } from 'vuexfire'

const state = {
  bar: {
    name: '',
    options: {},
    notes: '',
    messages: [],
    commentTypes: [],
    expenseTypes: [],
    nonCashTypes: [],
    shiftTypes: [],
    units: [],
    counters: [],
  },
}

const getters = {
  getOptions: (state) => state.bar.options,
  getOption: (state) => (option) => {
    if (!state.bar || !(option in state.bar.options)) return undefined
    return state.bar.options[option]
  },
  optionIs: (state) => (option, value) => {
    if (!(option in state.bar.options)) return false
    return state.bar.options[option] === value
  },
  getV2: (state) => {
    if (!state.bar || !state.bar.name) return false
    return state.bar.v2url
  },
  getBarName: (state) => {
    if (!state.bar || !state.bar.name) return 'TallyUp'
    return state.bar.name
  },
  getNotes: (state) => {
    if (!state.bar || !state.bar.notes) return ''
    return state.bar.notes
  },
  getMessages: (state) => {
    if (!state.bar || !state.bar.messages) return []
    return state.bar.messages
  },
  getExpenseTypes: (state) =>
    [...state.bar.expenseTypes].sort((a, b) => a.order - b.order),
  getCommentTypes: (state) =>
    [...state.bar.commentTypes].sort((a, b) => a.order - b.order),
  getCommentsObject: (state) => {
    return {
      ...state.bar.commentTypes.map((comment) => {
        return {
          ...comment,
          content: '',
        }
      }),
    }
  },
  getNonCashTypes: (state) =>
    [...state.bar.nonCashTypes].sort((a, b) => a.order - b.order),
  getCounters: (state) => {
    if (!state.bar?.counters) return []
    return [...state.bar.counters].sort((a, b) => a.order - b.order)
  },
  getShiftTypes: (state) =>
    state.bar && [...state.bar.shiftTypes].sort((a, b) => a.order - b.order),
  getUnits: (state) => [...state.bar.units].sort((a, b) => a.order - b.order),
  getUnitById: (state) => (unitId) => {
    return state.bar.units.find((unit) => unit.id === unitId)
  },
}

const mutations = {}

const actions = {
  setOptionsRef: firestoreAction(({ bindFirestoreRef }, ref) => {
    bindFirestoreRef('bar', ref)
  }),
}

export default {
  state,
  getters,
  mutations,
  actions,
}
