import store from '@/store/index'
import router from '@/router'
import { auth, bar } from '@/db'
import { Notification } from 'element-ui'
import * as Sentry from '@sentry/vue'

export default async function authStateHandler(user) {
  store.commit('IS_LOADED')

  if (user) {
    store.commit('UPDATE_USER', {
      providerData: { ...user.providerData[0] },
      email: user.email,
      uid: user.uid,
      metadata: { ...user.metadata },
    })
    const idToken = await auth.currentUser.getIdToken()
    store.commit('UPDATE_TOKEN', idToken)
    // CHECK IF USER HAS ACCESS TO THIS BAR
    try {
      const currentBar = await bar.get()
      let name = 'Admin'
      if (user.email !== 'aldealdo@gmail.com') {
        const person = await bar.collection('team').doc(user.uid).get()
        name = person.data().name || 'Unknown'
      }
      Sentry.configureScope(function (scope) {
        scope.setTag('Bar', currentBar.data().name)
        scope.setUser({
          id: user.uid,
          username: name,
        })
      })
    } catch (err) {
      // IF not BUITEN
      auth.signOut()
      router.replace({ name: 'login' }).catch(() => {})
      Notification({
        title: 'Erreur de connexion',
        message: 'Accès refusé, sorry!',
        type: 'error',
      })
    }
  } else {
    store.commit('UPDATE_USER', null)
    store.commit('UPDATE_TOKEN', null)
  }
}
