const getPersonTurnsAndLoans = (days, uid) => {
  const personPayroll = {
    turns: [],
    loans: [],
  }

  // Loop thru days
  for (const day in days) {
    // Loop thru shifts in a day
    for (const shiftId in days[day].shifts) {
      const shift = days[day].shifts[shiftId]
      const shiftInfos = {
        day: day,
        type: shiftId,
        shiftName: shift.name,
      }

      // Loop thru units in day's timsheet
      for (const unitId in shift.timesheet) {
        const unitTimesheets = shift.timesheet[unitId]

        if (uid in unitTimesheets) {
          personPayroll.turns.push({
            ...shiftInfos,
            ...shift.timesheet[unitId][uid],
          })
        }
      }

      // Loop thru Loans in shift
      shift.balance.expenses
        .filter((expense) => expense.isLoan)
        .filter((expense) => expense.employee.id === uid)
        .forEach((expense) => {
          personPayroll.loans.push({
            ...shiftInfos,
            ...expense,
          })
        })
    }
  }

  personPayroll.turns.sort((a, b) => a.start - b.start)
  personPayroll.loans.sort((a, b) => a.day > b.day)

  return personPayroll
}

export default getPersonTurnsAndLoans
