import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'

import auth from './modules/auth'
import bar from './modules/bar'
import team from './modules/team'
import shiftEditor from './modules/shiftEditor'
import week from './modules/week'
import month from './modules/month'
import app from './modules/app'
import vault from './modules/vault'
import breakpoints from './modules/breakpoints'
import unpaid from './modules/unpaid'
import payroll from './modules/payroll'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    bar,
    team,
    shiftEditor,
    week,
    month,
    app,
    unpaid,
    payroll,
    vault,
    breakpoints,
  },
  mutations: {
    ...vuexfireMutations,
  },
})
