import moment from 'moment'
import ucFirst from './ucFirst'

export default function readableDay(
  date,
  capitalize = true,
  format = 'dd DD/MM'
) {
  if (!date) return ''
  const readableDay = moment(date).locale('fr').format(format)
  if (capitalize) return ucFirst(readableDay)
  return readableDay
}
