const dayTotals = (shifts, dayTally, tallyType) => {
  const dayTotals = {
    cash: 0,
    invoices: 0,
    unofficialExpenses: 0,
    officialExpenses: 0,
    expenses: 0,
    nonCash: 0,
    balance: 0,
    unpaids: 0,
    unofficial: 0,
    officialCash: 0,
    official: 0,
    remainingCash: 0,
  }

  shifts.forEach(({ totals }) => {
    Object.entries(totals).forEach(([key, total]) => {
      dayTotals[key] += total
    })
  })

  if (tallyType === 'shift') {
    dayTotals.tally = shifts.reduce((result, shift) => result + shift.tally, 0)
  } else {
    dayTotals.tally = dayTally || 0
    dayTotals.officialCash =
      dayTotals.tally -
      dayTotals.officialExpenses -
      dayTotals.nonCash -
      dayTotals.invoices
    // dayTotals.officialCash = dayTotals.officialCash < 0 ? 0 : dayTotals.officialCash
    dayTotals.official =
      dayTotals.officialExpenses +
      dayTotals.officialCash +
      dayTotals.nonCash +
      dayTotals.invoices
    dayTotals.remainingCash = dayTotals.cash - dayTotals.officialCash
    dayTotals.unofficial =
      dayTotals.remainingCash + dayTotals.unofficialExpenses
  }
  dayTotals.officialPercentage = Math.round(
    (dayTotals.official / dayTotals.balance) * 10000
  )
  dayTotals.unofficialPercentage = Math.round(
    (dayTotals.unofficial / dayTotals.balance) * 10000
  )
  dayTotals.officialExpensesList = shifts.reduce((acc, next) => {
    return [...acc, ...next.officialExpensesList]
  }, [])
  return dayTotals
}

export default dayTotals
