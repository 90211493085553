const state = {
  nameFilter: '',
  unitFilter: [],
  rateTypeFilter: [],
  bankAmountFilter: '',
  showNotTransferredOnly: false,
  showNotPaidOnly: false,
  sortBy: 'name',
}

const getters = {
  filteredMonthTeam: (state, getters) => {
    const monthTeam = getters.getMonthTeam
    if (!monthTeam) return false

    return (
      Object.values(monthTeam)
        .filter((person) => person.id !== undefined)
        .filter((person) => {
          if (state.unitFilter.length === 0) return true
          return state.unitFilter.some((unit) => person.units.includes(unit))
        })
        .filter((person) => {
          if (state.rateTypeFilter.length === 0) return true
          return state.rateTypeFilter.every(
            (rateType) => person.rates[rateType].active
          )
        })
        // .filter(person => {
        //   if (state.bankAmountFilter === '') return true
        //   if (state.bankAmountFilter === 'empty' && !person.bankAmount) return true
        //   if (state.bankAmountFilter === 'filled' && person.bankAmount) return true
        // })
        .filter((person) => {
          const regex = new RegExp(state.nameFilter, 'i')
          if (!person.lastname) return person.name.match(regex)
          return person.name.match(regex) || person.lastname.match(regex)
        })
        // .filter(person => {
        //   if (!state.showNotTransferredOnly) return true
        //   if (!person.transferred) return true
        // })
        .filter((person) => {
          if (!state.showNotPaidOnly) return true
          return !person.paid
        })
        .sort((a, b) => {
          if (!a[state.sortBy] || !b[state.sortBy]) return 0
          return a[state.sortBy].localeCompare(b[state.sortBy])
        })
    )
  },
}

const mutations = {
  UPDATE_NAME_FILTER: (state, string) => {
    state.nameFilter = string
  },
  UPDATE_BANK_AMOUNT_FILTER: (state, string) => {
    state.bankAmountFilter = string
  },
  UPDATE_UNIT_FILTER: (state, arr) => {
    state.unitFilter = arr
  },
  UPDATE_RATE_TYPE_FILTER: (state, arr) => {
    state.rateTypeFilter = arr
  },
  UPDATE_SHOW_NOT_TRANSFERRED_ONLY: (state, bool) => {
    state.showNotTransferredOnly = bool
  },
  UPDATE_SHOW_NOT_PAID_ONLY: (state, bool) => {
    state.showNotPaidOnly = bool
  },
  UPDATE_SORT_BY: (state, str) => {
    state.sortBy = str
  },
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions,
}
