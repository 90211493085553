import rateTypes from '@/computation/rateTypes'

export default function contractHoursCalculation(
  profile,
  { debt, workedHours, loans }
) {
  // CONTRACT HOURS CALCULATION STRATEGY:
  // NE FONCTIONNE QU'AVEC LES SALAIRES HORAIRES
  // SALAIRE TOTAL = BANQUE + CASH
  // CASH = SURPLUS D'HEURES * €/h

  // SALAIRE EN BANQUE
  const bankAmount = profile.bankAmount ?? 0

  // HEURES SOUS CONTRAT (payés dans le salaire en banque)
  const contractHours = profile.contractHours ?? 0

  // HEURES HORS CONTRAT
  // Doit rester positif ou nul
  const paidHours = Math.max(0, workedHours - contractHours)

  // SALAIRE HORS CONTRAT = Heures Hors Contrat x Taux Horaire
  const outOfContractPay = rateTypes.hour.calc({
    workedHours: paidHours,
    profile,
  })

  // CASH = HEURES HORS CONTRAT x Taux Horaire - Avances - Dettes
  const cash = Math.max(0, outOfContractPay - loans - debt) // Le salaire ne peut pas être inférieur au montant en banque.

  // SALAIRE TOTAL = BANQUE + CASH
  const pay = bankAmount + outOfContractPay

  // SOLDE NEGATIF
  const negative = Math.min(outOfContractPay - loans - debt, 0)

  return {
    contractHours,
    paidHours,
    outOfContractPay,
    pay,
    cash,
    negative,
    bankAmount,
  }
}
