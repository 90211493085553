// GLOBALLY RESGISTERED COMPONENTS
import Vue from 'vue'

import Columns from '@/components/Utilities/Columns'
import Column from '@/components/Utilities/Column'
import Card from '@/components/Utilities/Card'
import Box from '@/components/Utilities/Box'
import PersonName from '@/components/Utilities/PersonName'

Vue.component('Columns', Columns)
Vue.component('Column', Column)
Vue.component('Card', Card)
Vue.component('PersonName', PersonName)
Vue.component('Box', Box)
