<template>
  <div class="modal-card">
    <h2 class="modal-card__title" v-if="title">{{ title }}</h2>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card',

  props: {
    title: {
      type: String,
      required: false,
    },
  },
}
</script>

<style lang="scss">
.modal-card {
  position: relative;
  border-radius: layout(border-radius);
  background-color: colors(white);
  padding: layout(spacing) * 0.5;
  overflow: hidden;

  &:not(:last-of-type) {
    margin-bottom: layout(spacing) * 0.5;
  }

  &__title {
    text-align: center;
    font-size: 1.6rem;
    padding-bottom: 0.5rem;
  }

  h6 {
    text-transform: uppercase;
  }
  p {
    font-size: 1.2rem;
  }
}
</style>
