import { bar } from '@/db'

export function dayRef(dayId) {
  return bar.collection('days').doc(dayId)
}

export function monthRef(monthId) {
  return bar.collection('months').doc(monthId)
}

export function weekRef(weekId) {
  return bar.collection('weeks').doc(weekId)
}

export function personRef(personId) {
  return bar.collection('team').doc(personId)
}
