import moment from 'moment'
import dayTotals from '@/computation/dayTotals'
import shiftTotals from '@/computation/shiftTotals'

function csvFriendlyNumber(input) {
  if (!input) return ''
  return String(input / 100).replace('.', ',')
}

const cashBox = (week, dontCountCoins, tallyType) => {
  if (!week.days) return {}

  const cashBox = {}

  Object.entries(week.days).forEach(([dayId, day]) => {
    const shiftsWithTotals = Object.entries(day.shifts).map(([, shift]) =>
      shiftTotals(shift, tallyType, dontCountCoins)
    )
    const { tally, nonCash, officialExpenses, officialExpensesList } =
      dayTotals(shiftsWithTotals, day.tally, tallyType)

    cashBox[dayId] = {
      day: moment(dayId).format('DD'),
      in: csvFriendlyNumber(tally),
      nonCash: csvFriendlyNumber(nonCash),
      officialExpenses: csvFriendlyNumber(officialExpenses),
      officialExpensesList,
    }
  })

  return cashBox
}

export default cashBox
