import moment from 'moment'
import dayTotals from '@/computation/dayTotals'
import shiftTotals from '@/computation/shiftTotals'

const accountingTotals = (period, dontCountCoins, tallyType, shiftTypes) => {
  if (!period || !('days' in period)) return {}
  const allDays = []
  const allShifts = []

  Object.entries(period.days).forEach(([dayId, day]) => {
    const shiftsWithTotals = Object.entries(day.shifts).map(([, shift]) =>
      shiftTotals(shift, tallyType, dontCountCoins)
    )
    allDays.push({
      ...dayTotals(shiftsWithTotals, day.tally, tallyType),
    })
    shiftsWithTotals.forEach((shift) => {
      allShifts.push({
        ...shiftTotals(shift, tallyType, dontCountCoins),
        weekDay: moment(dayId).format('E'),
      })
    })
  })

  const periodTotals = {
    cash: 0,
    invoices: 0,
    unofficialExpenses: 0,
    officialExpenses: 0,
    unpaids: 0,
    expenses: 0,
    nonCash: 0,
    balance: 0,
    officialCash: 0,
    official: 0,
    unofficial: 0,
    remainingCash: 0,
    tally: 0,
    shiftsCount: allShifts.length,
    daysCount: allDays.length,
  }

  allDays.forEach((dayTotals) => {
    Object.entries(dayTotals).forEach(([key, total]) => {
      periodTotals[key] += total
    })
  })
  periodTotals.officialPercentage = Math.round(
    (periodTotals.official / periodTotals.balance) * 10000
  )
  periodTotals.unofficialPercentage = Math.round(
    (periodTotals.unofficial / periodTotals.balance) * 10000
  )

  const averages = {
    types: {},
    weekParts: {
      week: 0,
      weekEnd: 0,
    },
  }

  shiftTypes.forEach(({ id }) => {
    const shiftsOfType = allShifts.filter((shift) => shift.id === id)
    const averageOfType =
      shiftsOfType.reduce(
        (results, shift) => results + shift.totals.balance,
        0
      ) / shiftsOfType.length
    averages.types[id] = Math.round(averageOfType)
  })
  const shiftOfWeekEnd = allShifts.filter((shift) => shift.weekDay >= 5)
  const avereageOfWeekEnd =
    shiftOfWeekEnd.reduce(
      (results, shift) => results + shift.totals.balance,
      0
    ) / shiftOfWeekEnd.length
  averages.weekParts.weekEnd = Math.round(avereageOfWeekEnd)

  const shiftOfWeek = allShifts.filter((shift) => shift.weekDay < 5)
  const avereageOfWeek =
    shiftOfWeek.reduce((results, shift) => results + shift.totals.balance, 0) /
    shiftOfWeek.length
  averages.weekParts.week = Math.round(avereageOfWeek)

  const counters = {}
  allShifts.forEach((shift) => {
    if (!shift.balance.counters) return
    Object.entries(shift.balance.counters).forEach(([key, value]) => {
      if (key in counters) {
        counters[key] += value.amount
      } else {
        counters[key] = value.amount
      }
    })
  })

  return {
    periodTotals,
    averages,
    counters,
  }
}

export default accountingTotals
