<template>
  <el-tooltip :content="fullName">
    <span>{{ choppedName }}</span>
  </el-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    person: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      getOption: 'getOption',
    }),

    advancedId() {
      return this.getOption('advancedId')
    },

    fullName() {
      return this.advancedId && this.person.lastname
        ? `${this.person.name} ${this.person.lastname}`
        : this.person.name
    },

    choppedName() {
      const [first, ...others] = this.person.name.split(' ')
      if (this.advancedId && this.person.lastname)
        return `${first} ${this.person.lastname.charAt(0)}.`
      const [last] = others.reverse()
      if (!last) return first
      return `${first} ${last.charAt(0)}.`
    },
  },
}
</script>
