const shiftTotals = (shift, tallyType, dontCountCoins = false) => {
  const cash = shift.balance.cash.reduce((result, item) => {
    if (dontCountCoins && !item.multiplier) return result
    return result + item.amount * (item.multiplier || 1)
  }, 0)
  const invoices =
    'invoices' in shift.balance
      ? shift.balance.invoices.reduce((result, invoice) => {
          return result + invoice.amount
        }, 0)
      : 0
  const unofficialExpenses = shift.balance.expenses
    .filter((expense) => !expense.isInvoice)
    .reduce((result, expense) => {
      return result + expense.amount
    }, 0)
  const officialExpenses = shift.balance.expenses
    .filter((expense) => expense.isInvoice)
    .reduce((result, expense) => {
      return result + expense.amount
    }, 0)
  const officialExpensesList = shift.balance.expenses.filter(
    (expense) => expense.isInvoice
  )
  const expenses = shift.balance.expenses.reduce((result, expense) => {
    return result + expense.amount
  }, 0)
  const unpaids = shift.balance.unpaids
    ? shift.balance.unpaids.reduce((result, expense) => {
        return result + expense.amount
      }, 0)
    : 0
  const nonCash = Object.values(shift.balance.nonCash).reduce(
    (result, item) => {
      return result + item.amount
    },
    0
  )
  const balance = cash + nonCash + expenses + invoices + unpaids

  let officialCash = 0
  let official = 0
  let remainingCash = 0

  if (tallyType === 'shift') {
    officialCash = shift.tally - officialExpenses - nonCash - invoices
    // officialCash = officialCash < 0 ? 0 : officialCash
    official = officialExpenses + officialCash + nonCash + invoices
    remainingCash = cash - officialCash
  }

  const unofficial = remainingCash + unofficialExpenses
  const officialPercentage = Math.round((official / balance) * 10000)
  const unofficialPercentage = Math.round((unofficial / balance) * 10000)

  const totals = {
    cash,
    invoices,
    unofficialExpenses,
    officialExpenses,
    expenses,
    unpaids,
    nonCash,
    balance,
    officialCash,
    official,
    unofficial,
    remainingCash,
    officialPercentage,
    unofficialPercentage,
  }

  if (tallyType === 'shift') {
    totals.tally = shift.tally
  }

  return {
    ...shift,
    totals,
    officialExpensesList,
  }
}

export default shiftTotals
