import moment from 'moment'

import { firestoreAction } from 'vuexfire'
import { monthRef } from '@/db/references'

import saveMonthAutomaticDebts from '@/db/actions/save/payroll/saveMonthAutomaticDebts'

import accountingTotals from '@/computation/accountingTotals'
import getPersonTurnsAndLoans from '@/computation/payroll/person'
import getPersonPayrollTotals from '@/computation/payroll/personTotals'
import monthTotals from '@/computation/payroll/monthTotals'
import expenseList from '@/computation/expenseList'

const state = {
  month: {},
  previousMonth: {},
}

const getters = {
  getTeamSize: (state, getters) => {
    return getters.getMonthTeamAsArray ? getters.getMonthTeamAsArray.length : 0
  },

  getMonthTeam: (state) => {
    if (!state.month || !state.month.days) return false
    const { team } = state.month
    if (!team) return false
    return team
  },

  getMonthTeamAsArray: (state) => {
    if (!state.month || !state.month.days) return false
    const { team } = state.month
    if (!team) return false
    return Object.values(team)
  },

  getPersonPayroll: (state, getters) => (uid) => {
    // ABOUT DEBTS
    // Automatically calculated debts data come from previous month
    // Manually calculated debts data come from current month
    if (!state.month || !state.month.days) return false
    const { days, team, manualDebts } = state.month
    if (!days || !team) return false
    const profile = team[uid]
    if (!profile) return false

    const { turns, loans } = getPersonTurnsAndLoans(days, uid)
    const nightStart = getters.getOption('nightStart')
    const disableAutoDebt = getters.getOption('disableAutoDebt')

    // Automatic debt is used by default
    // Manual debt is used when not null
    const debt = {
      amount: function () {
        if (disableAutoDebt) return 0
        return this.debtIsManual() ? this.manualDebt : this.automaticDebt
      },
      debtIsManual: function () {
        return (
          manualDebts &&
          Object.prototype.hasOwnProperty.call(manualDebts, uid) &&
          manualDebts[uid] !== null
        )
      },
      manualDebt:
        manualDebts &&
        Object.prototype.hasOwnProperty.call(manualDebts, uid) &&
        manualDebts[uid] !== null
          ? manualDebts[uid]
          : null,
      automaticDebt:
        state.previousMonth &&
        state.previousMonth.automaticDebts &&
        Object.prototype.hasOwnProperty.call(
          state.previousMonth.automaticDebts,
          uid
        )
          ? state.previousMonth.automaticDebts[uid]
          : 0,
    }

    const totals = getPersonPayrollTotals({
      profile,
      turns,
      loansList: loans,
      debt: debt.amount(),
      nightStart,
    })

    return {
      turns,
      loans,
      profile,
      totals,
      debt,
    }
  },

  getTeamDebts: (state, getters) => {
    if (!state.month || !state.month.days) return false
    const team = getters.getMonthTeamAsArray
    if (!team) return false
    const debts = {}
    team.forEach((person) => {
      const { totals } = getters.getPersonPayroll(person.id)
      if (!totals) return false
      debts[person.id] = totals.negative && totals.negative * -1 // Transform to positive
    })
    return debts
  },

  getMonthPayrollTotals: (state, getters) => {
    const workforce = getters.getMonthTeamAsArray
    if (!workforce) return false
    const workforceTotals = workforce.map((person) => {
      if (person.id === undefined) return false
      return getters.getPersonPayroll(person.id)
    })
    return monthTotals(workforceTotals, workforce.length)
  },

  getMonthAccountingTotals: (state, getters) => {
    if (!state.month || !state.month.days) return false
    const { days, team } = state.month
    const dontCountCoins = !getters.getOption('countCoins')
    const tallyType = getters.getOption('tally')
    const shiftTypes = getters.getShiftTypes

    if (days && team)
      return accountingTotals(
        state.month,
        dontCountCoins,
        tallyType,
        shiftTypes
      )
    return false
  },

  getMonthExpenses: (state) => {
    if (!state.month || !state.month.days) return false
    return expenseList(state.month)
  },
}

const mutations = {
  RESET_MONTH: (state) => {
    state.month = 'loading'
  },
}

const actions = {
  setMonthRef: firestoreAction(({ bindFirestoreRef }, ref) => {
    return bindFirestoreRef('month', ref)
  }),
  setPreviousMonthRef: firestoreAction(({ bindFirestoreRef }, ref) => {
    return bindFirestoreRef('previousMonth', ref)
  }),
  resetMonthRef: async ({ commit, dispatch }, month) => {
    await commit('RESET_MONTH')
    await dispatch('setMonthRef', monthRef(month))
    const prevMonthId = moment(month, 'YYYY-MM')
      .subtract(1, 'month')
      .format('YYYY-MM')
    await dispatch('setPreviousMonthRef', monthRef(prevMonthId))
    dispatch('saveMonthDebts', month)
  },
  // In order to keep track of debts we need
  // to detect negative cash amounts here
  // and save them in DB in current month's debt map
  saveMonthDebts: async ({ getters }, monthId) => {
    const teamDebts = getters.getTeamDebts
    await saveMonthAutomaticDebts(monthId, teamDebts)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
