import normalCalculation from './mode/normal'
import contractHoursCalculation from './mode/contractHours'
import commonCalculation from './mode/common'

const getPersonPayrollTotals = ({
  profile,
  turns,
  loansList,
  debt,
  nightStart,
}) => {
  // MAKE COMMON CALCULATIONS
  const { workedHours, dayHours, nightHours, days, loans } = commonCalculation({
    turns,
    nightStart,
    loansList,
  })
  const daysPerRole = {}
  daysPerRole[profile.access || 'none'] = days
  let salaryData = {}
  const workedHoursPerRole = {}
  workedHoursPerRole[profile.access || 'none'] = workedHours

  if (profile.rates.external) {
    salaryData = {
      contractHours: null,
      paidHours: null,
      outOfContractPay: null,
      pay: profile.bankAmount ?? 0,
      cash: 0,
      negative: 0,
      bankAmount: profile.bankAmount ?? 0,
    }
  } else if (profile.rates.contractHours) {
    // MAKE CONTRACT HOURS SALARY CALCULATION
    salaryData = contractHoursCalculation(profile, { debt, workedHours, loans })
  } else {
    // MAKE NORMAL SALARY CALCULATION
    salaryData = normalCalculation(profile, { debt, loans, workedHours })
  }
  return {
    workedHours,
    workedHoursPerRole,
    dayHours,
    nightHours,
    days,
    daysPerRole,
    loans,
    debt,
    ...salaryData,
  }
}

export default getPersonPayrollTotals
