// TODO : USE NEW MODULAR WAY INTRODUCED ON FIREBASE V9
import Firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import configs from '@/db/firebase.config.json'
import authStateHandler from '@/db/authStateHandler'

// USE ENVIRONEMENT TO CHOOSE FIREBASE ENV
const config = configs.production

// INIT AND EXPORT FIREBASE APP
export const firebaseApp = Firebase.initializeApp(config)

// INIT AND EXPORT FIREBASE AUTH HANDLER
export const auth = firebaseApp.auth()
auth.setPersistence(Firebase.auth.Auth.Persistence.SESSION)

// DEFINE CURRENT BAR
const currentBar =
  process.env.NODE_ENV === 'production'
    ? window.location.hostname.split('.').slice(-2).join('-')
    : process.env.VUE_APP_BAR

// INIT AND EXPORT CURRENT BAR DOCUMENT REF
export const bar = firebaseApp.firestore().collection('bars').doc(currentBar)

// SET HANDLER FOR AUTHENTICATION STATE CHANGES
auth.onAuthStateChanged(authStateHandler)
