import getDuration from '@/utils/getDuration'
import getDayHours from '@/utils/getDayHours'
import getNightHours from '@/utils/getNightHours'

export default function commonCalculation({ turns, nightStart, loansList }) {
  // TODO: Check if two turn in same day
  // Nb of turns
  const days = turns.length

  // The amount of hours this user worked this month
  const workedHours = turns.reduce(
    (result, item) => result + getDuration(item.start, item.end),
    0
  )
  // The amount of day hours this user worked this month
  const dayHours = turns.reduce(
    (result, item) => result + getDayHours(item.start, item.end, nightStart),
    0
  )
  // The amount of night hours this user worked this month
  const nightHours = turns.reduce(
    (result, item) => result + getNightHours(item.start, item.end, nightStart),
    0
  )
  // The amount of loans this person had this month
  const loans = loansList.reduce((result, item) => result + item.amount, 0)

  return {
    workedHours,
    dayHours,
    nightHours,
    days,
    loans,
  }
}
