const state = {
  breakpoints: [
    { name: 'small', device: 'mobile', min: 0, max: 767 },
    { name: 'medium', device: 'tablet', min: 768, max: 1023 },
    { name: 'large', device: 'desktop', min: 1024, max: 1399 },
    { name: 'xlarge', device: 'desktop', min: 1400, max: Infinity },
  ],
  windowWidth: 0,
}

const getters = {
  breakpoint: (state) => {
    return state.breakpoints.find(
      (bpt) => state.windowWidth >= bpt.min && state.windowWidth <= bpt.max
    )
  },
  breakpointName: (state, getters) => {
    return getters.breakpoint.name
  },
  device: (state, getters) => {
    return getters.breakpoint.device
  },
  isDesktop: (state, getters) => {
    return getters.device === 'desktop'
  },
  isMobile: (state, getters) => {
    return getters.device === 'mobile'
  },
  isNotDesktop: (state, getters) => {
    return getters.device !== 'desktop'
  },
}

const mutations = {
  UPDATE_BREAKPOINTS: (state, array) => {
    state.breakpoints = [...array]
  },
  UPDATE_BREAKPOINT: (state, string) => {
    state.breakpoint = string
  },
  UPDATE_WINDOW_WIDTH: (state, number) => {
    state.windowWidth = number
  },
  UPDATE_DEVICE: (state, string) => {
    state.device = string
  },
}

const actions = {
  updateWindowWidth: (store, width) => {
    if (width !== store.state.windowWidth) {
      store.commit('UPDATE_WINDOW_WIDTH', width)
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
