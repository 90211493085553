// Automatic Debts are automatically
// calculated from month results
// and cannot be manually changed
import { monthRef } from '@/db/references'

export default function saveMonthAutomaticDebts(monthId, value) {
  if (!value) return
  return new Promise((resolve, reject) => {
    if (!monthId)
      reject(new Error('Argument(s) for saveMonthAutomaticDebts missing'))
    // Check if ref exists
    monthRef(monthId)
      .get()
      .then((monthDoc) => {
        // If it does, update
        if (monthDoc.exists) {
          monthRef(monthId)
            .update({
              automaticDebts: value,
            })
            .then(() => {
              resolve()
            })
            .catch((err) => reject(err))
        }
        // If not, do nothing
        resolve(false)
      })
  })
}
